.share-social {
  @extend .card;
  .share-social-body {
    @extend .card-body;
    @extend .text-center;
  }
  small {
    @extend .d-block;
    margin-bottom: 0.5rem;
  }
}
