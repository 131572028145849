@media print {
  #ScrollToTop,
  #sidebar,
  #footer,
  #wrapper-topbar,
  #sidebar-button,
  .breadcrumb-wrapper,
  .btns-previous-next,
  .skip-to-main,
  .edit-github,
  .page-toc {
    display: none !important;
  }

  .page-wrapper,
  .wrapper-main {
    height: unset;
    position: unset;
  }

  .page-wrapper,
  .page-content {
    padding: none;
    width: unset;
    overflow-x: unset;
    transition: unset;
  }

  body,
  html {
    background-color: white !important;
    background-image: none !important;
  }

  #main {
    height: 100% !important;
  }

  .page-content {
    height: auto !important;
    padding: 0 !important;
  }
}
