html,
body {
  scroll-behavior: smooth;
}
body {
  display: flex;
  flex-direction: column;

  &.auth-page {
    background-color: var(--theme-primary);
    background-image: none;
  }

  &.error-page {
    background-color: var(--theme-primary);
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2311304d' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
  }

  &.internal {
    padding-bottom: 64px;

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }
  }
}

iframe {
  @extend .position-relative;
  margin: 1rem 0 2rem;
}

::selection,
::-moz-selection {
  color: var(--theme-text-selection-text-color);
  background-color: var(--theme-text-selection-bg-color);
  border-radius: 16px;
}

label,
.label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

small,
.small {
  color: var(--theme-small-text-color);
  .form-group & {
    padding: 2px 4px 0;
  }
}

hr,
.hr {
  @extend .w-100;
  background-color: var(--theme-divider-bg-color);
  border: 0;
  margin-left: 0;
  margin-right: 0;
  opacity: 1;

  &.major {
    height: 0.25rem;
    margin: 1rem 0;
  }

  &.minimal,
  &.minor {
    height: 1px;
    margin: 0.5rem 0;
  }
}

// Added for links, but not button style, in paragraph text or list items
p,
li {
  a {
    //text-decoration-line      : var(--theme-text-decoration-line);
    //text-decoration-style     : var(--theme-text-decoration-style);
    //text-decoration-color     : var(--theme-text-decoration-color);
    //text-decoration-thickness : var(--theme-text-decoration-thickness);
    &:hover {
      text-decoration-style: double;
    }
    &.btn {
      text-decoration: none;
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }
}

blockquote,
.blockquote {
  padding: 24px 32px 6px;
  background-color: var(--theme-blockquote-bg-color);
  border-left: ($border-width * 4) $border-style
    var(--theme-blockquote-border-color);
}
blockquote + blockquote,
.blockquote + .blockquote {
  padding-top: 0;
  margin-top: -16px;
}

legend,
.legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  white-space: normal;
}
