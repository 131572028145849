.official-banner {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--theme-official-banner-text-color);
  background-color: var(--theme-official-banner-bg);
  border-color: var(--theme-official-banner-bg) !important;
  .container-fluid {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .btn {
    @extend .btn-link;
    @extend .btn-sm;
    @extend .border-0;
    @extend .bg-transparent;
    @extend .shadow-none;
    @extend .text-white;
    @extend .fw-bold;
    &[aria-expanded="true"]{
      .icon {
        transform: rotate(180deg);
      }
    }
  }
  .expander {
    transform: translateY(-1px);
  }
  .fw-bold {
    @extend .text-white;
  }
}
.media {
  display: flex;
  align-items: flex-start;
  .media-body {
    flex: 1;
  }
}
