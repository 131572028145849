.toast {
  .toast-body {
    background-color: transparent;
  }
  &.toast-success {
    background-color: var(--theme-toast-bg-success-color);
    color: var(--theme-toast-text-success-color);
    .toast-header {
      background-color: var(--theme-toast-header-success-bg-color);
      color: var(--theme-toast-header-success-text-color);
    }
  }
  &.toast-danger {
    background-color: var(--theme-toast-bg-danger-color);
    color: var(--theme-toast-text-danger-color);
    .toast-header {
      background-color: var(--theme-toast-header-danger-bg-color);
      color: var(--theme-toast-header-danger-text-color);
    }
  }
  &.toast-info {
    background-color: var(--theme-toast-bg-info-color);
    color: var(--theme-toast-text-info-color);
    .toast-header {
      background-color: var(--theme-toast-header-info-bg-color);
      color: var(--theme-toast-header-info-text-color);
    }
  }
  &.toast-warning {
    background-color: var(--theme-toast-bg-warning-color);
    color: var(--theme-toast-text-warning-color);
    .toast-header {
      background-color: var(--theme-toast-header-warning-bg-color);
      color: var(--theme-toast-header-warning-text-color);
    }
  }
}
.toast-header {
  position: relative;
  .btn-close {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }
}
