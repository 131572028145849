.feature-options-titles {
  h2,
  .h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  h3,
  .h3 {
    @extend .lead;
  }
  hr,
  .hr {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.feature-text-box-over-photo {
  @extend .position-relative;
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-end;
  @extend .flex-lg-row;
  @extend .bg-image-size-cover;
  @extend .bg-image-position-center;

  &-section {
    padding: 1.25rem;
    margin-top: 1rem;
    margin-right: 0;
    margin-bottom: 1rem;
    margin-left: 9.5rem;
    @include media-breakpoint-up(md) {
      margin-top: 10rem;
      margin-bottom: 10rem;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 1 50%;
      margin-right: 0;
      margin-left: auto;
    }
  }
}
.feature-boxed-in-photo {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.feature-cta {
  background-color: var(--theme-feature-cta-single-bg-color);
  padding: 9.5rem 0;
  @extend .text-center;
  @extend .text-md-end;
  .feature-big-text {
    @extend .h2;
    @extend .text-md-start;
    color: var(--theme-feature-cta-single-text-color);
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
  .feature-small-text {
    @extend .text-md-start;
    color: var(--theme-feature-cta-single-text-color);
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}
.feature-cta-double {
  background-color: var(--theme-feature-cta-double-bg-color);
  padding-top: 5rem;
  padding-bottom: 5rem;
  .feature-big-text {
    @extend .h2;
    margin-bottom: 1rem;
    color: var(--theme-feature-cta-double-text-color);
  }
  .feature-small-text {
    color: var(--theme-feature-cta-double-text-color);
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  .btn {
    display: block;
    margin-bottom: 1rem;
  }
  @media screen and (min-width: 768px) {
    .btn {
      display: inline;
      margin-bottom: 0;
    }
  }
}
.feature-icons-text {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: var(--theme-feature-icons-text-bg-color);
  .feature-big-text {
    @extend .h2;
    @extend .fw-bold;
    @extend .text-center;
    @extend .text-lg-start;
    color: var(--theme-feature-icons-text-text-color);
  }
  .feature-small-text {
    margin-bottom: 2rem;
    color: var(--theme-feature-icons-text-text-color);
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  .feature-icons {
    @extend .display-4;
    color: var(--theme-feature-icons-text-text-color);
  }
}
.feature-languages {
  background-color: var(--theme-feature-languages-bg-color);
  @include media-breakpoint-up(lg) {
    padding: 4rem;
  }
  .feature-big-text {
    @extend .h2;
    //this is redundant from the h2 inheritance above
    @extend .fw-bold;
    color: var(--theme-feature-languages-text-color);
  }
  .feature-small-text {
    color: var(--theme-feature-languages-text-color);
  }
  .feature-item {
    @extend .rounded;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
    background-color: var(--theme-feature-languages-item-bg-color);
    p {
      @extend .h4;
      @extend .fw-light;
      @extend .text-center;
      margin-bottom: 0;
    }
  }
}
.feature-many-icons {
  background-color: var(--theme-feature-many-icons-text-bg-color);
  padding: 1rem;
  @include media-breakpoint-up(lg) {
    padding: 4rem;
  }
  .feature-big-text {
    @extend .h2;
    margin-bottom: 2rem;
    color: var(--theme-feature-many-icons-text-color);
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  .feature-small-text {
    color: var(--theme-feature-many-icons-text-color);
    margin-bottom: 0;
  }
}
.feature-set {
  background-color: var(--theme-feature-set-bg-color);
  padding: 1.5rem;
  hr.minor {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .feature-big-text {
    @extend .h2;
    color: var(--theme-feature-set-text-color);
  }
  .feature-lead-text {
    margin-bottom: 0;
    color: var(--theme-feature-set-text-color);
  }
  .feature-small-text {
    margin-bottom: 0.5rem;
    color: var(--theme-feature-set-text-color);
  }
  .feature-lead-icon {
    @extend .h4;
    margin-bottom: 0.5rem;
    color: var(--theme-feature-set-icon-color);
  }
}
