.form-control {
  &.disabled,
  &[disabled],
  &[readonly] {
    box-shadow: none;
  }
}
.form-group {
  margin-bottom: 1rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  & > .col,
  & > [class*="col-"] {
    padding: 0 5px;
  }
}

.form-title {
  // this is used on form templates
  @extend .h3;
  @extend .mb-6;
  @extend .text-primary;
}
