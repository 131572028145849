.hero-dual-pane {
  @extend .position-relative;
  @extend .bg-image-position-center;
  @extend .bg-image-size-cover;
  background-color: var(--theme-hero-dual-pane-bg-color);
  padding-top: 10rem;
  padding-bottom: 10rem;
  .overlay {
    @extend .position-absolute;
    @extend .opacity-75;
  }
  .overlay-left {
    background-color: var(--theme-hero-dual-pane-overlay-left-bg-color);
  }
  .overlay-right {
    background-color: var(--theme-hero-dual-pane-overlay-right-bg-color);
  }
  .container {
    @extend .position-relative;
  }
  .logo {
    @extend .img-fluid;
    margin-bottom: 1rem;
    width: 128px;
  }
  .hero-big-text {
    @extend .h1;
    @extend .fw-bold;
    color: var(--theme-hero-dual-pane-text-color);
  }
  .hero-lead-text {
    @extend .h4;
    color: var(--theme-hero-dual-pane-text-color);
  }
  .hero-small-text {
    color: var(--theme-hero-dual-pane-text-color);
  }
}
.hero-single-pane,
.hero-solo-pane {
  background-color: var(--theme-hero-single-pane-bg-color);
  .half-left {
    @extend .bg-image-size-cover;
    @extend .bg-image-position-center;
  }
  .half-right {
    padding-top: 8rem;
    padding-bottom: 8rem;
    background-color: var(--theme-hero-single-pane-bg-color);
    color: var(--theme-hero-single-pane-small-text-color);
    .row {
      @extend .justify-content-center;
      @extend .align-items-center;
    }
  }
  .hero-big-text {
    @extend .h2;
    color: var(--theme-hero-single-pane-header-text-color);
  }
  .hero-small-text {
    color: var(--theme-hero-single-pane-small-text-color);
  }
  .img-fluid {
    @extend .d-lg-none;
  }
}
.hero-boxed-in-photo {
  background-color: var(--theme-hero-boxed-photo-bg-color);
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  .hero-big-text {
    @extend .h2;
    margin-top: 2rem;
    color: var(--theme-hero-boxed-photo-text-color);
  }
  .hero-small-text {
    color: var(--theme-hero-boxed-photo-text-color);
  }
  img {
    margin-top: 1.25rem;
  }
  @media screen and (min-width: 992px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
    .hero-big-text {
      margin-top: 0;
    }
    img {
      margin-top: 0;
    }
  }
}
.hero-text-color-toned-photo {
  background-color: var(--theme-hero-color-toned-bg-color);
  @extend .position-relative;
  @extend .bg-image-position-center;
  @extend .bg-image-size-cover;
  padding-top: 10rem;
  padding-bottom: 2.5rem;
  .overlay {
    @extend .position-absolute;
    opacity: var(--theme-hero-color-toned-overlay-opacity);
    background-color: var(--theme-hero-color-toned-bg-color);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
  .container,
  .container-fluid {
    position: relative;
    z-index: 1;
  }
  .hero-big-text {
    @extend .h2;
    @extend .fw-bold;
    @extend .text-shadow !optional;
    @extend .shadow-primary !optional;
    color: var(--theme-hero-color-toned-text-color);
  }
  .hero-small-text {
    @extend .text-shadow !optional;
    @extend .shadow-primary !optional;
    color: var(--theme-hero-color-toned-text-color);
  }
}
.hero-textbox-over-photo {
  @extend .position-relative;
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-end;
  @extend .flex-lg-row;
  @extend .bg-image-size-cover;
  @extend .position-relative;
  @extend .bg-image-position-center;
  padding-top: 10rem;
  padding-bottom: 10rem;
  .overlay {
    @extend .position-absolute;
    opacity: var(--theme-textbox-over-photo-overlay-opacity);
    background-color: var(--theme-textbox-over-photo-bg-color);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .hero-textbox {
    background-color: var(--theme-textbox-over-photo-bg-color);
    padding: 2.5rem;
  }
  .hero-big-text {
    @extend .h2;
    @extend .fw-bold;
    color: var(--theme-textbox-over-photo-text-color);
  }
  .hero-small-text {
    color: var(--theme-textbox-over-photo-text-color);
  }
}

.overlay {
  + * {
    z-index: 2;
  }
}

.overlay-left {
  top: 0;
  right: 0;
  bottom: 50%;
  left: 0;

  @media screen and (min-width: 992px) {
    top: 0;
    right: 50%;
    bottom: 0;
    left: 0;
  }
}

.overlay-right {
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;

  @media screen and (min-width: 992px) {
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
  }
}
