.img-official {
  width: 23px;
  height: 23px;
}

.image-credit {
  @extend .small;
  @extend .text-center;
  margin-top: 0.25rem;
  margin-bottom: 0;

  a {
    color: inherit;
  }
}

.brand-logo {
  @extend .d-flex;
  @extend .align-items-center;
  width: 120px;
  height: 48px;

  svg {
    .mark,
    .type {
      fill: var(--theme-navbar-text-color);
    }
  }
}

.bg-image-size-cover {
  background-size: cover;
}

.bg-image-position-center {
  background-position: 50%;
}
