caption {
  caption-side: bottom;
  border-top: 1px dashed var(--theme-border-color);
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  font-size: $font-size-base * 0.875;
  color: var(--theme-gray-700);
  background-color: var(--theme-gray-white);
  border-color: var(--theme-table-border-color);
  th,
  td {
    padding: var(--theme-table-cell-padding);
    vertical-align: top;
    border-top-width: var(--theme-table-border-width);
    background-color: var(--theme-gray-white);
  }
  thead th {
    vertical-align: bottom;
    border-top-width: var(--theme-table-border-width-thick);
    border-top-style: solid;
    border-bottom-width: var(--theme-table-border-width-thick);
    border-bottom-style: solid;
  }
  tbody + tbody {
    border-top-width: var(--theme-table-border-width-thick);
    border-top-style: solid;
  }
  thead {
    tr {
      th:first-child {
        border-top-left-radius: 4px;
      }
      th:last-child {
        border-top-right-radius: 4px;
      }
    }
  }
  tbody {
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 4px;
      }
      td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }
  > :not(caption) > * > * {
    border-bottom-width: 0;
  }
}
.table-striped {
  > tbody > tr:nth-of-type(#{$table-striped-order}) > * {
    --#{$prefix}table-bg-type: var(--theme-table-striped-bg);
  }
}
