.toggle-token {
  // we are overriding Bootstrap by using nested rules.
  // if the default theme is removed, Bootstrap defaults take over.
  &.form-check {
    display: inline-block;
    padding: 0;
    margin: 0 0.5rem 0 0 !important;
    .form-check-label {
      position: relative;
      padding: 4px 16px 4px 32px;
      background-color: var(--bs-white);
      border: 2px solid var(--theme-form-border-color);
      border-radius: 1rem;
      .icon {
        position: absolute;
        top: 8px;
        left: 10px;
        color: var(--theme-form-border-color);
        &:first-of-type {
          opacity: 1;
        }
        &:last-of-type {
          opacity: 0;
        }
      }
      &:hover,
      &:active {
        cursor: pointer;
      }
    }
    .form-check-input {
      @extend .visually-hidden;
      &:checked {
        + .form-check-label {
          color: var(--bs-white);
          background-color: var(--theme-ui-priority);
          border-color: var(--theme-ui-priority);
          .icon {
            position: absolute;
            top: 8px;
            left: 10px;
            color: var(--bs-white);
            &:first-of-type {
              opacity: 0;
            }
            &:last-of-type {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .form-check-input[disabled] ~ .form-check-label {
    border-color: var(--theme-form-disabled-bg-color);
    color: var(--theme-form-border-color);
    .icon {
      &:first-of-type {
        opacity: 0.5;
      }
    }
    &:hover,
    &:active {
      cursor: default;
    }
  }
  .form-check-input:checked[disabled] ~ .form-check-label {
    border-color: transparent !important;
    background-color: var(--theme-info-500) !important;
  }
}
.was-validated .toggle-token .form-check-input:invalid ~ .form-check-label {
  border-color: var(--theme-danger);
}
.was-validated
  .toggle-token
  .form-check-input:checked:valid
  ~ .form-check-label {
  color: $white;
  background-color: var(--theme-success);
  border-color: var(--theme-success);
}
// big switch
.custom-switch.custom-switch-large {
  width: 100%;
  height: 40px;
  min-height: unset;
  padding: 0;

  .custom-control-label {
    display: block;
    color: transparent;

    &::before {
      top: 0;
      left: 0;
      width: 100%;
      height: 40px;
      border-width: 2px;
      border-radius: 20px;
    }

    &::after {
      top: 4px;
      left: 4px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      transition: left ease-in-out 250ms;
    }
  }

  .custom-switch-text {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transition: color ease-out 150ms;
    transform: translateX(-50%) translateY(-50%);

    &.inactive {
      z-index: 10;
      color: $dark;
    }

    &.active {
      z-index: 0;
      color: transparent;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      left: calc(100% - 36px);
      transform: unset;
    }
  }

  .custom-control-input:checked ~ .custom-switch-text {
    &.inactive {
      color: transparent;
    }

    &.active {
      z-index: 11;
      color: var(--bs-white);
    }
  }
}
// tiles
.checkbox-tile,
.radio-tile {
  padding-left: 0;
  margin: 0 0 0 0;
  &.form-check-inline {
    vertical-align: top;
    min-width: 200px;
    max-width: 400px;
  }
  .form-check-input {
    position: absolute;
    left: -999em;
    right: auto;
  }
  .form-check-label {
    position: relative;
    display: block;
    background-color: $white;
    color: var(--theme-gray-700);
    border: 2px solid var(--theme-form-border-color);
    border-radius: 8px;
    margin-top: 8px;
    padding: 12px 16px 12px 40px;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      position: absolute;
      margin-top: 2px;
      left: 12px;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 2px;
      box-shadow: 0 0 0 2px var(--theme-form-border-color);
    }
  }
  .form-check-input:checked + label {
    background-color: var(--theme-ui-light);
    border-color: var(--theme-form-border-color);
    &:before {
      background-color: var(--theme-info-900);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0 0 0 2px var(--theme-info);
    }
  }
  .form-check-input:focus + label {
    &:before {
      outline-style: solid;
      outline-color: var(--theme-focus-color);
      outline-width: 4px;
      outline-offset: 4px;
    }
  }
}
.radio-tile {
  .form-check-label {
    &:before {
      border-radius: 50%;
    }
  }
  .form-check-input:checked + label {
    &:before {
      background-color: var(--bs-white);
      background-image: none;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 16px;
      left: 14px;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: var(--theme-ui-priority);
    }
  }
}
.tile-description {
  display: block;
  font-size: 12px;
}
