.modal-content {
  background-color: var(--theme-modal-dialog-bg-color);
  border: $card-border-width solid var(--theme-modal-dialog-border-color);
  .close {
    @extend .position-relative;
    opacity: 1;
  }
}
.modal-title {
  @extend .h5;
  color: var(--theme-modal-dialog-title-color);
}
