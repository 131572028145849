.card {
  margin-bottom: 1rem;
  background-color: var(--theme-card-bg-color);
  border-color: var(--theme-card-border-color);
  @extend .shadow-sm;
  .content-container & {
    @extend .shadow-none;
    background-color: var(--theme-card-nested-bg-color);
  }
}
.card-fieldset-entry,
.card-in-form {
  // this is a card with discrete data in a fieldset
  margin-bottom: 8px;
  background-color: var(--theme-card-nested-bg-color);
}
.card-warning {
  border-color: var(--theme-danger);
}
.card-authenticate {
  border-color: var(--theme-gray-100);
}
.card-error {
  border-color: var(--theme-danger);
}
.card {
  .stretched-link {
    &:hover {
      @extend .text-decoration-underline;
    }
  }
}
