.page-wrapper .theme {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 2px;
  border-radius: 4px;
}
.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: -260px;
  z-index: 1000;
  width: 260px;
  height: 100%;
  max-height: 100%;
  transition: left 250ms ease-in-out;

  .toggled & {
    left: 0;
  }
}
@include media-breakpoint-up(md) {
  .sidebar-wrapper {
    left: 0;
  }
  .toggled .sidebar-wrapper {
    left: -260px;
  }
}
.sidebar-wrapper ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.sidebar-wrapper a {
  text-decoration: none;
}
.sidebar-content {
  position: relative;
  height: calc(100% - 30px);
  max-height: calc(100% - 30px);
  overflow-y: auto;
}
.sidebar-content.desktop {
  overflow-y: hidden;
}
.sidebar-wrapper .sidebar-brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background-color: var(--theme-sidebar-brand-bg-color);
  img {
    height: 24px;
  }
}
.sidebar-wrapper .sidebar-brand > a {
  @extend .position-relative;
  flex-grow: 1;
  font-weight: 700;
  padding: 10px 20px;
}
#close-sidebar {
  flex: 0 0 30px;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}
.sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}
.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 60px;
  padding: 2px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 12px;
}
.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.sidebar-wrapper .sidebar-header .user-info {
  float: left;
}
.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}
.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status {
  margin-top: 4px;
  font-size: 11px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status i {
  margin-right: 4px;
  font-size: 8px;
  color: #5cb85c;
}
.sidebar-wrapper .sidebar-search > div {
  padding: 10px 20px;
}
.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
  @extend .list-unstyled;
}
.sidebar-wrapper .sidebar-menu .header-menu span {
  display: inline-block;
  padding: 15px 20px 5px;
  font-size: 14px;
  font-weight: 700;
}
.sidebar-wrapper .sidebar-menu ul li a i {
  width: 30px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}
.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}
.sidebar-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
}
.sidebar-footer > a {
  position: relative;
  flex-grow: 1;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}
.badge-sonar {
  position: absolute;
  top: 0;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #980303;
  border-radius: 50%;
}
.badge-sonar::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  border: 2px solid #980303;
  border-radius: 50%;
  opacity: 0;
  animation: sonar 1.5s infinite;
}
.page-wrapper .page-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: 100%;
  padding-top: 0;
  padding-left: 0;
  overflow-x: hidden;
  transition: padding-left 250ms ease-in-out;

  @include media-breakpoint-up(md) {
    padding-left: 260px;
  }
}
@include media-breakpoint-up(md) {
  .page-wrapper.toggled .page-content {
    padding-left: 0;
  }
}
.sidebar-wrapper,
.sidebar-footer {
  background: var(--theme-sidebar-wrapper-bg-color);
}
.sidebar-wrapper .sidebar-header,
.sidebar-wrapper .sidebar-search {
  border-top: 1px solid var(--theme-sidebar-header-border-color);
}
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text {
  border-color: transparent;
  box-shadow: none;
}
.sidebar-wrapper .sidebar-header .user-info .user-role,
.sidebar-wrapper .sidebar-header .user-info .user-status,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-menu ul li a,
.sidebar-footer > a {
  color: var(--theme-sidebar-link-color);
  &:hover {
    color: var(--theme-sidebar-link-hover-color);
  }
}
.sidebar-wrapper .sidebar-brand > a:hover {
  background-color: transparent;
}
.sidebar-wrapper .sidebar-header .user-info {
  background-color: transparent;
}
.page-wrapper.agency-theme.toggled #close-sidebar {
  color: fade-out($white, 0.25);
}
.page-wrapper.agency-theme.toggled #close-sidebar:hover {
  color: #fff;
}
.sidebar-wrapper .sidebar-menu .header-menu span {
  color: fade-out($white, 0.25);
}
.sidebar-footer {
  padding-top: 4px;
  border-top: 1px solid var(--theme-sidebar-header-border-color);
}
.sidebar-footer > a:first-child {
  border-left: none;
}
.sidebar-footer > a:last-child {
  border-right: none;
}
.sidebar-item {
  overflow-x: hidden;
}
.sidebar-nav-item {
  @extend .position-relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  .icon {
    align-self: start;
    margin-right: 4px;
    transform: translateY(3px);
  }
  &:hover,
  &:active {
    background-color: var(--theme-sidebar-item-bg-color);
  }
  &:hover {
    span:last-of-type {
      @extend .text-decoration-underline;
    }
  }
}
.sidebar-dropdown-header {
  display: flex;
  a:nth-last-child(n + 2),
  a:nth-last-child(n + 2) ~ .sidebar-nav-item {
    width: calc(100% - 44px);
  }
}
.sidebar-dropdown-header-expand {
  @extend .position-relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 1px $border-style transparent;
  .icon {
    line-height: 44px;
    transform: rotate(90deg);
  }
  &:hover,
  &:active {
    cursor: pointer;
    color: var(--theme-sidebar-link-hover-color);
    background-color: var(--theme-sidebar-item-bg-color);
  }
  &:hover {
    border-color: var(--theme-sidebar-link-color);
  }
  &.active {
    .icon {
      transform: rotate(-90deg);
    }
  }
}
.sidebar-submenu {
  display: none;
  background-color: var(--theme-sidebar-submenu-bg-color);
  > ul {
    .icon {
      margin: 3px 5px 0 3px;
      font-size: 10px;
      opacity: 0.5;
    }
    .active {
      .icon {
        opacity: 1;
      }
    }
  }
}
.wrapper-main {
  position: relative;
  min-height: 100vh;
}
main.main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.wrapper-topbar {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  z-index: 900;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
  padding: 0.25rem 0.5rem;
  color: var(--theme-topbar-text-color);
  background-color: var(--theme-topbar-bg-color);
  .dropdown {
    .dropdown-toggle {
      color: var(--theme-topbar-text-color);
    }
    .dropdown-menu {
      background-color: var(--theme-topbar-bg-color);
      border: 0;
      border-top: 1px solid white;
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: -0.35rem;
        right: 0.75rem;
        border-style: solid;
        border-width: 0 0.3rem 0.3rem 0.3rem;
        border-color: transparent transparent var(--theme-topbar-text-color)
          transparent;
      }
    }
    .dropdown-item {
      color: var(--theme-topbar-text-color);
      &:hover,
      &:focus {
        background-color: var(--theme-topbar-text-color);
        color: var(--theme-topbar-bg-color);
      }
    }
  }
  img {
    height: 24px;
  }
  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }
}
