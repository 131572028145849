.progress-large {
  border-bottom: $border-width $border-style var(--theme-progress-border-color);
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: var(--theme-progress-bg-color);
  .btn {
    @extend .shadow-none;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    color: var(--theme-progress-description-color);
    background-color: var(--theme-progress-bg-color);
    border-color: var(--theme-progress-step-bg-color);
  }
  @media screen and (min-width: 768px) {
    .btn {
      margin-bottom: 0;
    }
  }
}
.progress-info {
  @extend .d-flex;
}
.progress-description {
  @extend .fw-bold;
  color: var(--theme-progress-description-color);
  margin-bottom: 0;
  margin-top: 0.25rem;
  padding-left: 0.25rem;
}
.progress-description-counter {
  @extend .small;
  @extend .d-block;
  color: var(--theme-progress-description-counter-color);
  padding-left: 0.25rem;
}
.progress-description-text {
  @extend .d-block;
  color: var(--theme-progress-description-text-color);
}
.progress-step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  background-color: var(--theme-progress-step-bg-color);
  border-color: var(--theme-progress-step-border-color);
}
.progress-step-digit {
  color: var(--theme-progress-step-digit-color);
  font-size: 24px;
  text-align: center;
}
