@mixin focus-default {
  box-shadow: none;
  outline: 0.25rem solid var(--theme-focus-color);
  outline-offset: 0.25rem;
}

@mixin focus-box {
  box-shadow: none;
  outline: none;

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border: 0.25rem solid var(--theme-focus-color);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin focus-box-outline {
  box-shadow: none;
  outline: none;

  &:before {
    position: absolute;
    content: "";
    border: 0.25rem solid var(--theme-focus-color);
    top: -0.5rem;
    left: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
  }
}

// Cover all interactable elements with default focus
select,
textarea,
input,
button,
area,
summary,
a {
  &:focus {
    @include focus-default;
  }
}

//Bootstrap overrides
.form-control,
.form-select,
.custom-select,
.custom-file .custom-file-label,
.nav-link,
.btn,
.official-banner .btn.expander {
  &:focus {
    @include focus-default;
  }
}
.official-banner .btn.expander {
  &:focus {
    outline-color: $white !important;
  }
}

.brand-link {
  &:focus {
    outline: none;
    svg,
    img {
      @include focus-default;
    }
  }
}

.custom-file-input {
  &:focus ~ .custom-file-label {
    @include focus-default;
  }
}

.toggle-token > .form-check-input {
  &:focus,
  &.focus {
    + .form-check-label {
      @include focus-default;
    }
  }
}

//switching specific anchors to only draw a box inside their DOM container
.sidebar-brand > a,
.sidebar-nav-item,
.sidebar-dropdown-header-expand,
.page-link {
  &:focus {
    @include focus-box;
  }
}

//change up the outline for closing buttons
button.close {
  &:focus {
    box-shadow: none;
    outline: 0.25rem dashed var(--theme-focus-color);
  }
}

.stretched-link:focus {
  @include focus-box-outline;

  &:focus-visible {
    outline: none;
  }
}
