.breadcrumb-wrapper {
  padding: 0.75rem 1rem;
  display: block;
  background-color: var(--theme-breadcrumb-bg-color);
  border-bottom: 1px solid var(--theme-breadcrumb-border-color);
  @media screen and (min-width: 1024px) {
    padding: 0.75rem 0;
  }
}
.breadcrumb {
  @extend .rounded-0;
  @extend .border-0;
  margin-bottom: 0;
  background-color: var(--theme-breadcrumb-bg-color);
  @media screen and (min-width: 1024px) {
    padding-left: 1rem;
  }
  @media screen and (min-width: 1200px) {
    padding-left: 1.5rem;
  }
}
.breadcrumb-item {
  display: none;
  &:first-child {
    display: list-item;
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom-color: var(--theme-breadcrumb-link-color);
    }
  }
  &:nth-last-child(2) {
    display: list-item;
  }
  &:last-child {
    display: list-item;
  }
  &.active {
    color: var(--theme-breadcrumb-active-color);
    .fas {
      margin: 3px 0 0 4px;
    }
  }
  a {
    color: var(--theme-breadcrumb-link-color);
    text-decoration: none;
    &:hover,
    &:active {
      color: inherit;
      text-decoration: underline;
    }
  }
  @media screen and (min-width: 1024px) {
    display: list-item;
  }
}
