.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;
  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
  &:hover {
    @extend .text-decoration-underline;
  }
}
.nav-tabs {
  border-bottom: var(--theme-border-width) var(--theme-border-style)
    var(--theme-border-color);
  .nav-item {
    margin-right: 0.5rem;
    margin-bottom: -$nav-tabs-border-width;
  }
  .nav-link {
    border: $nav-tabs-border-width solid transparent;
    @include border-top-radius($nav-tabs-border-radius);
    &.disabled {
      color: var(--theme-ui--light);
      background-color: transparent;
      border-color: transparent;
    }
    &.active {
      border-color: var(--theme-nav-tabs-link-active--border-color);
      border-bottom: none;
    }
    &:hover {
      background-color: var(--theme-gray-white);
      border-bottom: none;
    }
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    border-color: $nav-tabs-link-active-border-color;
    border-bottom: 2px solid var(--theme-gray-white);
  }
  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}
.nav-pills {
  &:not(.flex-column) {
    .nav-item {
      &:not(:last-of-type) {
        margin-right: 0.5rem;
      }
    }
  }
  &.flex-column {
    .nav-item {
      margin-top: 0.5rem;
    }
  }
  .nav-link {
    color: var(--theme-link-color);
    background-color: var(--theme-gray-white);
    border: 2px solid var(--theme-ui);
    transition: $transition-base;
    @include border-radius($nav-pills-border-radius);
    &:hover,
    &:active {
      background-color: var(--theme-gray-200);
    }
    &:not(.disabled) {
      background-color: var(--theme-gray-white);
      border: $border-width $border-style var(--theme-ui-priority);
    }
  }
  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}
.nav-fill {
  > .nav-link,
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}
.nav-justified {
  > .nav-link,
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}
.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
.navbar {
  background-color: var(--theme-navbar-bg-color);
  color: var(--theme-navbar-text-color);
  &.navbar-minimal {
  }
  &.navbar-basic {
    @extend .navbar-expand-lg;
    .navbar-toggler {
      color: var(--theme-navbar-text-color);
      margin-left: auto;
    }
    .navbar-nav {
      margin-left: auto;
    }
    .nav-link {
      color: var(--theme-navbar-text-color);
    }
    .btn {
      margin-top: 0.5rem;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: 0.5rem;
      }
    }
  }
  &.navbar-search {
    @extend .d-block;
    @extend .navbar-expand-lg;
    .container,
    .container-fluid {
      padding-top: 0;
      padding-bottom: 0;
    }
    .navbar-toggler {
      color: var(--theme-navbar-text-color);
      margin-left: auto;
    }
    .navbar-collapse {
      @include media-breakpoint-up(lg) {
        margin-left: -0.5rem;
      }
    }
    .navbar-nav {
      @extend .justify-content-end;
      @extend .w-100;
      @extend .mt-4;
      @extend .mt-lg-1;
      padding-top: 0.5rem;
      border-top-color: var(--theme-navbar-inner-border-color);
      border-top-width: var(--theme-border-width);
      border-top-style: solid;
    }
    .nav-link {
      color: var(--theme-navbar-text-color);
    }
    .search-form {
      @extend .mt-2;
      @extend .mt-lg-1;
      width: 100%;
      @include media-breakpoint-up(lg) {
        width: unset;
      }
    }
    .form-control {
      @extend .shadow-none;
      background-color: transparent;
      color: var(--theme-navbar-form-control-text-color);
      border-color: var(--theme-navbar-form-control-border-color);
      border-top-left-radius: $btn-border-radius !important;
      border-bottom-left-radius: $btn-border-radius !important;
      &::-webkit-search-cancel-button {
        // todo find a way to style this
      }
    }
    .btn {
      .icon {
        @extend .opacity-100;
      }
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: 0.5rem;
      }
    }
  }
  &.navbar-iconic {
    @extend .navbar-expand-lg;
    .brand-logo {
      margin-bottom: 0;
    }
    .navbar-toggler {
      color: var(--theme-navbar-text-color);
      margin-left: auto;
    }
    .navbar-icon-links {
      .nav-item {
        &:hover,
        &:active {
          .nav-link {
            background-color: rgba(var(--bs-white-rgb), 0.25);
            .icon {
              opacity: 1;
            }
          }
          .icon-link-border-bottom {
            background-color: var(--theme-navbar-inner-border-hover-color);
          }
        }
        @include media-breakpoint-up(lg) {
          margin-left: 0.75rem;
          text-align: center;
        }
      }
      .nav-link {
        background-color: rgba(var(--bs-white-rgb), 0);
        border-radius: 4px 4px 0 0;
        color: var(--theme-navbar-text-color);
        opacity: 0.85;
        .icon {
          opacity: 0.62;
          transition: $transition-base;
        }
        &.active {
          @extend .fw-bold;
          opacity: 1;
          .icon {
            opacity: 1;
          }
          & + .icon-link-border-bottom {
            background-color: var(--theme-navbar-inner-border-hover-color);
          }
        }
      }
      .icon-link-border-bottom {
        background-color: "";
        height: var(--theme-border-width);
      }
      @include media-breakpoint-up(lg) {
        margin-left: auto;
        .nav-link {
          span:not(.icon) {
            display: block;
          }
        }
      }
    }
  }
}
