.page-header {
  background-color: var(--theme-page-title-bg-color);
  border-bottom: $border-width $border-style
    var(--theme-page-title-border-color);
  border-radius: 0;

  @include media-breakpoint-up(lg) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.page-title {
  @extend .shadow-sm;
  background-color: var(--theme-page-title-bg-color);
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-bottom: $border-width $border-style
    var(--theme-page-title-border-color);
  // todo code page title, muted badges in CSS variables
  .badge-primary {
    background-color: var(--theme-badge-chip-primary-bg-dim-color);
    color: var(--theme-badge-chip-primary-text-dim-color);
  }
  .badge-secondary {
    background-color: var(--theme-badge-chip-secondary-bg-dim-color);
    color: var(--theme-badge-chip-secondary-text-dim-color);
  }
  .badge-ui {
    background-color: var(--theme-badge-chip-ui-bg-dim-color);
    color: var(--theme-badge-chip-ui-text-dim-color);
  }
  .badge-success {
    background-color: var(--theme-badge-chip-success-bg-dim-color);
    color: var(--theme-badge-chip-success-text-dim-color);
  }
  .badge-info {
    background-color: var(--theme-badge-chip-info-bg-dim-color);
    color: var(--theme-badge-chip-info-text-dim-color);
  }
}
.page-title-text {
  @extend .h2;
  margin-bottom: 0;
  color: var(--theme-page-title-text-color);
  .icon {
    color: var(--theme-page-title-icon-color);
  }
  p {
    margin-bottom: 0;
  }
}
