footer,
.footer {
  .brand-logo {
    @extend .position-relative;
    svg {
      width: 100%;
      .mark,
      .type {
        fill: $white;
      }
    }
  }
  &.footer-basic {
    background-color: var(--theme-footer-basic-bg-color);
    margin-top: 3rem;
    .links-wrapper {
      @extend .d-flex;
      @extend .flex-wrap;
      @extend .justify-content-center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      .btn-link {
        color: var(--theme-footer-basic-link-color);
        @extend .border-0;
        @extend .rounded-0;
      }
    }
  }
  &.footer-utility {
    background-color: var(--theme-footer-utility-bg-color);
    padding: 1rem 0;
    margin-top: auto;
    .brand-logo {
      margin-bottom: 0;
    }
    hr {
      background-color: var(--theme-footer-utility-divider-color);
      opacity: 0.25;
    }
    .btn-link {
      color: var(--theme-footer-utility-link-color);
    }
  }
}
