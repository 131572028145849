.alert {
  border-radius: var(--theme-alert-border-radius);
  padding-right: 46px;
  padding-left: 42px;
  .alert-icon {
    @extend .position-absolute;
    top: 19px;
    left: 16px;
    width: 18px;
    height: 18px;
  }
  .close,
  .btn-close {
    @extend .position-absolute;
    top: -0.125rem;
    right: 0;
    width: 21px;
    height: 21px;
    opacity: 1;
  }
}
.alert-success {
  color: var(--theme-alert-success-text-color);
  background-color: var(--theme-alert-success-bg-color);
  border-color: var(--theme-alert-success-border-color);
  .alert-link {
    color: var(--theme-alert-success-text-color);
  }
}
.alert-info {
  color: var(--theme-alert-info-text-color);
  background-color: var(--theme-alert-info-bg-color);
  border-color: var(--theme-alert-info-border-color);
  .alert-link {
    color: var(--theme-alert-info-text-color);
  }
}
.alert-warning {
  color: var(--theme-alert-warning-text-color);
  background-color: var(--theme-alert-warning-bg-color);
  border-color: var(--theme-alert-warning-border-color);
  .alert-link {
    color: var(--theme-alert-warning-text-color);
  }
}
.alert-danger {
  color: var(--theme-alert-danger-text-color);
  background-color: var(--theme-alert-danger-bg-color);
  border-color: var(--theme-alert-danger-border-color);
  .alert-link {
    color: var(--theme-alert-danger-text-color);
  }
}
