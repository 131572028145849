.skip-to-main {
  position: absolute;
  top: 0;
  left: -220px;
  z-index: $zindex-skip-to-main;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 44px;
  color: var(--theme-skip-link-color);
  background-color: var(--theme-skip-link-bg-color);
  border-bottom-right-radius: 1rem;
  transition: $transition-base;

  &:active,
  &:focus {
    left: 0;
  }
}
