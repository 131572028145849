.btn {
  box-shadow: none;
  &:hover {
    @extend .text-decoration-underline;
  }
}
.btn-link {
  @extend .shadow-none;
  @extend .border-0;
  @extend .text-decoration-none;
  background-color: transparent !important;
}
.btn-container {
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: var(--theme-button-container-bg-color);
  border: 2px solid var(--theme-button-container-border-color);
  border-radius: 0.25rem;
  .btn {
    width: 100%;
    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
    &.btn-link {
      margin-right: 0;
    }
    @media screen and (min-width: 1024px) {
      width: unset;
      &:not(:last-of-type) {
        margin-right: 0.5rem;
        margin-bottom: 0;
      }
    }
  }
  .col-12 {
    @media screen and (min-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.btn-card-corner {
  @extend .position-absolute;
  top: 1.25rem;
  right: 1.25rem;
}
.btn-card-edit {
  @extend .position-absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}
.btn-add-this,
.btn-add-data {
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .shadow-none;
  @extend .w-100;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: transparent;
  border: 2px dashed var(--theme-ui);
  div,
  .wrapper {
    @extend .rounded;
    background-color: var(--theme-ui);
    color: var(--bs-white);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  &:hover {
    background-color: var(--theme-gray-100);
  }
  &:active {
    background-color: var(--theme-gray-200);
  }
}
a.btn {
  text-decoration: none;
  &:hover,
  &:active {
    text-decoration: inherit;
  }
}
#ScrollToTop,
.btn-scroll-top {
  @extend .position-fixed;
  right: 2rem;
  bottom: 5rem;
  z-index: $zindex-scroll-to-top;
}
//this class has similar attributes to btn-link
.btn-prev-next-section {
  color: var(--theme-ui-priority);
  background-color: transparent !important;
  @extend .border-0;
  @extend .shadow-none;
}
.btns-previous-next,
.btns-previous-forward {
  .container-fluid {
    padding-top: 1.675rem;
    padding-bottom: 1.625rem;
  }
  hr.major {
    margin-top: 0.75rem;
    margin-bottom: 0;
  }
}
.sidebar-button {
  @extend .btn;
  @extend .btn-sm;
  position: relative;
  margin-right: 0.25rem;
  color: var(--theme-topbar-text-color);
  @media screen and (min-width: 992px) {
    order: 1;
  }
}
