.data-tile {
  @extend .card;
}
.data-tile-body {
  @extend .card-body;
  p {
    &:nth-of-type(1) {
      @extend .label;
    }
    &:nth-of-type(2) {
      margin-bottom: 0;
    }
  }
}
